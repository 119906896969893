/* Standard Eric Meyer reset */
/* http://meyerweb.com/eric/tools/css/reset/
   v4.0 | 20180602
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, main, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section {
	display: block;
}
/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
    display: none;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/* Get the variables in early so they're available */
/*
  The breakpoints for all resolutions represented as pairs with the breakpoint name and its associated pixel width.
  @media (--medium) { ...styles... }
*/
/* Above-targt breakpoints */
/* Below-target breakpoints */
/* Motion reduction breakpoint */
/* High contrast breakpoints */
/*
  Allows you to create your own selectors
  http://cssnext.io/features/#custom-selectors
  .MyModule:--enter {
    ...
  }
*/
/*
  The z-indexes for the project.
  z-index: var(--zindex--nav);
*/
/* Import the utility classes */
.sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}
.color--primary-1 {
    color: #FEE199;
  }
.color--primary-2 {
    color: #FFDA5E;
  }
.color--primary-3 {
    color: #EF5D3C;
  }
.color--primary-4 {
    color: #263B8D;
  }
.color--secondary-1 {
    color: #113A18;
  }
.color--secondary-2 {
    color: #FFC1CF;
  }
.color--secondary-3 {
    color: #5E202D;
  }
.color--secondary-4 {
    color: #957342;
  }
.color--white {
    color: #FFFFFF;
  }
.color--white .heading {
      color: #FFDA5E;
    }
.color--white .link:hover,.color--white .link:active,.color--white .link:focus {
        color: #FFFFFF;
      }
.color--gray-1 {
    color: #757575;
  }
.color--black {
    color: #231F20;
  }
.bg--p1 {
    background-color: #FEE199;
  }
.bg--p2 {
    background-color: #FFDA5E;
  }
.bg--p3 {
    background-color: #EF5D3C;
  }
.bg--p4 {
    background-color: #263B8D;
  }
.bg--s1 {
    background-color: #113A18;
  }
.bg--s2 {
    background-color: #FFC1CF;
  }
.bg--s3 {
    background-color: #5E202D;
  }
.bg--s4 {
    background-color: #91AABA;
  }
.bg--sup-1 {
    background-color: #957342;
  }
.bg--sup-2 {
    background-color: #866841;
  }
.bg--sup-3 {
    background-color: #A78658;
  }
.bg--white {
    background-color: #FFFFFF;
  }
.bg--gray-1 {
    background-color: #757575;
  }
.bg--gray-1 {
    background-color: #757575;
  }
.bg--gray-2 {
    background-color: #8A8687;
  }
.bg--gray-3 {
    background-color: #DCDCDC;
  }
.bg--gray-4 {
    background-color: #F4F5F5;
  }
.bg--black {
    background-color: #231F20;
  }
.rhythm > * + *, .rhythm--default > * + * { margin-top: 1em; }
.rhythm--small > * + * { margin-top: 0.5em; }
.rhythm--large > * + * { margin-top: 2em; }
.rhythm--extra-large > * + * { margin-top: 4em; }
.rhythm--deep * + *, .rhythm--deep-default * + * { margin-top: 1em; }
.rhythm--deep-small * + * { margin-top: 0.5em; }
.rhythm--deep-large * + * { margin-top: 2em; }
.rhythm--deep-extra-large * + * { margin-top: 4em; }
.text--align-center {
    text-align: center;
  }
.text--align-left {
    text-align: left;
  }
.text--align-right {
    text-align: right;
  }
.text--bold {
    font-weight: 600;
  }
.text--italic {
    font-style: italic;
  }
.text--light {
    font-weight: 300;
  }
.text--small {
    font-size: .875rem;
  }
/* Import root styles */
@font-face {
  font-family: Soehne;
  font-weight: 300;
  src: url("../fonts/soehne-leicht.woff2") format('woff2');
}
@font-face {
  font-family: Soehne;
  font-weight: 300;
  font-style: italic;
  src: url("../fonts/soehne-leicht-italic.woff2") format('woff2');
}
@font-face {
  font-family: Soehne;
  font-weight: 400;
  src: url("../fonts/soehne-buch.woff2") format('woff2');
}
@font-face {
  font-family: Soehne;
  font-weight: 400;
  font-style: italic;
  src: url("../fonts/soehne-buch-italic.woff2") format('woff2');
}
@font-face {
  font-family: Soehne;
  font-weight: 600;
  src: url("../fonts/soehne-kraftig.woff2") format('woff2');
}
@font-face {
  font-family: Soehne;
  font-weight: 600;
  font-style: italic;
  src: url("../fonts/soehne-kraftig-italic.woff2") format('woff2');
}
@font-face {
  font-family: Soehne;
  font-weight: 600;
  font-style: italic;
  src: url("../fonts/soehne-kraftig.woff2") format('woff2');
}
@font-face {
  font-family: SangBleuKingdom;
  font-weight: 400;
  src: url("../fonts/sang-bleu-kingdom-regular.otf") format('woff2');
}
* {
  box-sizing: border-box;
}
html, body {
  box-sizing: inherit;
  color: #231F20;
  font-family: 'Soehne', sans-serif;
  font-weight: 300;
  line-height: 1.5;
  scroll-behavior: smooth;
}
html.lock-body, body.lock-body {
    overflow: hidden;
  }
body.scroll-lock {
    overflow: hidden;
  }
img {
  max-width: 100%;
  vertical-align: bottom;
}
strong {
  font-weight: 600;
}
em {
  font-style: italic;
}
::selection {
  background-color: rgba(255, 218, 94, .4);
}
/* Import all component styles */
.fivestone {
  align-items: center;
  display: flex;
  min-height: 80vh;
}
.fivestone dl {
    color: #757575;
    font-size: .875rem;
    display: flex;
    flex-wrap: wrap;
    max-width: 14rem;
    padding: 1.5rem;
    transition: .2s cubic-bezier(.455, .03, .4, 1.645) 0s;
  }
.fivestone dl dt {
      flex: 0 0 10%;
      font-weight: 600;
    }
.fivestone dl dd {
      flex: 1 1 90%;
    }
.aspect {
  position: relative;
}
.aspect::before {
    display: block;
    content: '';
  }
.aspect > * {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
.aspect--16by9::before {
      padding-top: 56.25%;
      content: '';
    }
.aspect--4by3::before {
      padding-top: 75%;
      content: '';
    }
.aspect--2by1::before {
      padding-top: 50%;
      content: '';
    }
.aspect--610by713::before {
      padding-top: 116.8852459016393%;
      content: ''
    }
.aspect--1by1::before {
      padding-top: 100%;
      content: '';
    }
.blockquote, blockquote {
  font-family: 'SangBleuKingdom', serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 31px;
}
.blockquote .figcaption, blockquote .figcaption {
    font-family: 'Soehne', sans-serif;
    font-size: 1.125rem;
    font-weight: 300;
    margin-top: 1rem;
  }
.blockquote em, blockquote em {
    background-image: linear-gradient(90deg, #FFDA5E 0%,#FFDA5E 100%);
    background-position: 0% 100%;
    background-size: 0% 100%;
    background-repeat: no-repeat;
    font-style: normal;
    line-height: 1.05;
    transition: .2s cubic-bezier(.455, .03, .4, 1.645) 0s;
    transition-property: background-size;
    transition-delay: .45s;
    transition-duration: .5s;
  }
.is-selected .blockquote em, .is-selected blockquote em {
      background-position: 0% 100%;
      background-size: 100% 100%;
    }
.button {
  align-content: center;
  align-items: center;
  background-color: transparent;
  border: 3px solid #231F20;
  border-radius: 36px;
  color: #231F20;
  cursor: pointer;
  display: inline-flex;
  font-family: 'Soehne', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  height: 3.5rem;
  justify-content: center;
  line-height: 1;
  margin-left: 1.5rem;
  min-width: 15rem;
  outline: none;
  padding: 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: .2s ease-in;
  white-space: nowrap;
}
.button--default::before, .button.link::before {
      border-top: 3px solid;
      content: '';
      display: block;
      left: -1.5rem;
      position: absolute;
      transition: .2s cubic-bezier(.455, .03, .4, 1.645) 0s;
      width: 42px;
      top: 50%;
    }
.button--default:hover, .button.link:hover {
      border-color: #957342;
      color: #957342;
      text-decoration: none;
    }
.button--default:hover::before, .button.link:hover::before {
        border-color: #957342;
        left: calc(100% - 1.25rem);
      }
.button--default:active, .button.link:active {
      background-color: rgba(149, 115, 66, .07);
      border-color: #957342;
      color: #957342;
    }
.button--default:active::before, .button.link:active::before {
        border-color: #957342;
        left: calc(100% - 1.875rem);
      }
.button--default:focus, .button.link:focus {
      outline: none;
    }
.button--default[disabled], .button.link[disabled] {
      border-color: #888888;
      color: #888888;
      cursor: default;
      pointer-events: none;
    }
.button--default[disabled]::before, .button.link[disabled]::before {
        border-color: #888888;
      }
.button--default span, .button.link span {
      display: block;
      flex: 1 1 auto;
    }
.button--default.button--white, .button.link.button--white {
      background-color: transparent;
      border-color: #FFFFFF;
      color: #FFFFFF;
    }
.button--default.button--white:hover, .button--default.button--white:active, .button.link.button--white:hover, .button.link.button--white:active {
        border-color: #A78658;
        color: #A78658;
      }
.button--default.button--white:hover::before, .button--default.button--white:active::before, .button.link.button--white:hover::before, .button.link.button--white:active::before {
          border-color: #A78658;
        }
.button--default.button--white:active, .button.link.button--white:active {
        background-color: rgba(255, 255, 255, .07);
      }
.button--default.button--white:focus, .button.link.button--white:focus {
        color: #FFFFFF;
      }
.button--default.button--white[disabled], .button.link.button--white[disabled] {
        background-color: inherit;
        border-color: #888888;
        color: #888888;
      }
.button[type="submit"] {
    background-image: url(../images/icons/arrow-black.svg);
    background-position: center right;
    background-repeat: no-repeat;
    border: none;
    border-radius: 0;
    font-weight: 300;
    justify-content: left;
    padding: 0 6rem 0 0;
    min-width: 10rem;
  }
.button[type="submit"] span {
      text-align: left;
    }
.button[type="submit"]::before {
      display: none;
      content: '';
    }
.button[type="submit"].button--white {
      background-image: url(../images/icons/arrow-white.svg);
      color: white;
    }
.button[type="submit"].button--white:hover, .button[type="submit"].button--white:active {
        color: #FFFFFF;
      }
.button[type="submit"]:hover {
      background-image: url(../images/icons/arrow-brown.svg);
      background-position: right .4rem center;
    }
.button[type="submit"]:active {
      background-color: transparent;
      background-position: right center;
    }
.button[type="submit"][disabled] {
      color: #888888;
      pointer-events: none;
    }
.button--burger {
    border: 0;
    border-radius: 0;
    color: inherit;
    height: 32px;
    min-width: 0;
    padding: 0;
    position: relative;
    width: 32px;
    z-index: 200;
  }
.button--burger::before, .button--burger::after {
      background-color: currentColor;
      border: none;
      content: '';
      display: block;
      height: 4px;
      left: 6px;
      position: absolute;
      right: 0;
      transition: .2s cubic-bezier(.455, .03, .4, 1.645) 0s;
      transition-duration: .4s;
      transform-origin: center center;
      width: 24px;
    }
.button--burger::before {
      top: 8px;
      content: '';
    }
.button--burger::after {
      top: 20px;
      content: '';
    }
.button--burger.active::before {
        background-color: #FFFFFF;
        top: 12px;
        transform: rotate(45deg);
        content: '';
      }
.button--burger.active::after {
        background-color: #FFFFFF;
        top: 12px;
        transform: rotate(-45deg);
        content: '';
      }
.button--carousel {
    border: none;
    border-radius: 0;
    color: currentColor;
    height: 2.5rem;
    margin: 0;
    min-width: 0;
    padding: 0;
    transition: none;
    width: 5.375rem;
  }
.button--carousel + .button--carousel {
      margin-left: 1.3125rem;
    }
.button--carousel svg path {
      fill: currentColor;
    }
.button--carousel[disabled] {
      color: rgba(255, 255, 255, .3) !important;
      cursor: default;
    }
.button--carousel[disabled] svg path {
        fill: rgba(255, 255, 255, .3) !important;
      }
.card {
  border: solid 1px var(--color-gray);
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
}
.card--centered {
    align-items: center;
    border: none;
    display: flex;
    flex-direction: column;
  }
.card--shadowed {
    border: 0;
    box-shadow: 0 4px 8px 2px rgba(0, 0, 0, .125);
  }
.card--shadowed:hover,.card--shadowed:active,.card--shadowed:focus {
      box-shadow: 0 4px 8px 2px rgba(0, 0, 0, .25);
      transition: .2s cubic-bezier(.455, .03, .4, 1.645) 0s;
    }
.carousel-panel {
  /* CarouselPanel */
}
.component {
  padding-bottom: 3rem;
  padding-top: 3rem;
}
.component--no-padding {
    padding: 0
  }
.component--bottom-padding {
    padding-bottom: 3rem;
    padding-top: 0;
  }
.component--top-padding {
    padding-bottom: 0;
    padding-top: 3rem;
  }
.component--large-padding {
    padding-bottom: calc(3rem * 1.5);
    padding-top: calc(3rem * 1.5);
  }
.component--bottom-large-padding {
    padding-bottom: calc(3rem * 1.5);
    padding-top: 0;
  }
.component--top-large-padding {
    padding-bottom: 0;
    padding-top: calc(3rem * 2);
  }
.component--larger-padding {
    padding-bottom: calc(3rem * 2);
    padding-top: calc(3rem * 2);
  }
.component--bottom-larger-padding {
    padding-bottom: calc(3rem * 2);
    padding-top: 0;
  }
.component--top-larger-padding {
    padding-bottom: 0;
    padding-top: calc(3rem * 2);
  }
.component--small-padding {
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
  }
.component--bottom-small-padding {
    padding-bottom: 1.5rem;
    padding-top: 0;
  }
.component--top-small-padding {
    padding-bottom: 0;
    padding-top: 1.5rem;
  }
.dl {
  display: flex;
  flex-wrap: wrap;
}
.dl .dt {
    flex: 1 0 33%;
    font-weight: 600;
  }
.dl .dt::after {
      content: ': ';
    }
.dl .dd {
    flex: 0 1 67%;
  }
.figure {
  /* Figure */
}
.form__item {
    padding: calc(1.5rem / 2) 0;
  }
.form legend {
    font-size: 2.125rem;
    margin-bottom: 1.5rem;
  }
.heading-eyebrow {
  display: block;
  font-size: .75rem;
  margin-bottom: -1.5rem;
  text-transform: uppercase;
}
.heading-eyebrow--prominent {
    background-color: #FFDA5E;
    color: #FFFFFF;
    display: inline-block;
    font-size: 1.25rem;
    padding: 1.25rem 2.5rem;
  }
.heading, .richtext h1, .richtext h2, .richtext h3, .richtext h4, .richtext h5, .richtext h6 {
  font-family: 'Soehne', sans-serif;
  font-range: 320px 1280px; /* viewport widths between which font-size is fluid */
  font-weight: 400;
  line-height: 1.2em;
}
.heading--h1, .heading--h1-major, .richtext h1 {
    font-family: 'SangBleuKingdom', serif;
    font-size: calc(2rem + 1.25 * ((100vw - 26.25rem) / 53.75));
  }
.heading--h1-major {
    font-size: calc(3.125rem + 5.5 * ((100vw - 26.25rem) / 53.75));
  }
.heading--h2, .richtext h2 {
    font-size: calc(2rem + 0.5 * ((100vw - 26.25rem) / 53.75));
  }
.heading--h3, .richtext h3 {
    font-size: calc(1.75rem + 0.375 * ((100vw - 26.25rem) / 53.75));
  }
.heading--h4, .richtext h4 {
    font-size: calc(1.5rem + 0.25 * ((100vw - 26.25rem) / 53.75));
  }
.heading--h5, .richtext h5 {
    font-size: calc(1.25rem + 0.0625 * ((100vw - 26.25rem) / 53.75));
    font-weight: 600;
  }
.heading--h6, .richtext h6 {
    font-size: calc(1rem + 0.125 * ((100vw - 26.25rem) / 53.75));
  }
.heading--alt {
    font-family: 'SangBleuKingdom', serif
  }
.heading--light {
    font-weight: 300 !important;
  }
.heading--heavy {
    font-weight: 600 !important;
  }
.image, .richtext img {
  height:auto;
  max-width: 100%;
}
.image--rounded {
    border-radius: 100%;
  }
.image--background {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
.image__lazy-box {
      bottom: 4px;
      left: 0;
      opacity: 1;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 3;
    }
.image__lazy-box .image__low-res {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transition: .2s cubic-bezier(.455, .03, .4, 1.645) 0s;
        width: 100%;
      }
.image__lazy-panel {
      background-color: #DCDCDC;
      display: block;
      height: 100%;
      opacity: 0;
      position: absolute;
      width: 100%;
      z-index: 3;
    }
.image__lazy-wrapper {
      display: inline-block;
      position: relative;
    }
.image__lazy-wrapper.pre-load .image__low-res {
          filter: blur(6px);
        }
.image__lazy-wrapper.pre-load .image__lazy-panel {
          opacity: .5;
        }
.image__lazy-wrapper.is-loaded .image__low-res {
          filter: none;
        }
.image__lazy-wrapper.is-loaded .image__lazy-box {
          opacity: 0;
          transition: .2s cubic-bezier(.455, .03, .4, 1.645) 0s;
        }
.image__lazy-wrapper.is-loaded .image.is-lazy {
          opacity: 1;
          transition: .2s cubic-bezier(.455, .03, .4, 1.645) 0s;
        }
.input {
  background-color: #FFFFFF;
  border: 0;
  border-bottom: 1px solid #888888;
  color: #231F20;
  font-family: 'Soehne';
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 1;
  padding: 0 0 .5rem 0;
  max-width: 25rem;
  width: 100%;
}
.input:focus {
    border-color: #000;
    outline: 0;
  }
.input::placeholder {
    color: #757575;
    opacity: .8;
  }
.input[type="radio"] + .label::after {
        border-radius: calc(14px / 2);
        content: '';
      }
.input[type="radio"] + .label::before {
        border-radius: calc(24px / 2);
        content: '';
      }
.input[type="file"] {
    border-color: transparent;
  }
.input[type="date"], .input[type="datetime-local"], .input[type="time"], .input.select {
    background-image: url('../images/icons/select.svg');
    background-position: top 50% right .5rem;
    background-repeat: no-repeat;
  }
/* removes the default calendar icon in webkit browsers */
.input[type="date"]::-webkit-calendar-picker-indicator, .input[type="datetime-local"]::-webkit-calendar-picker-indicator, .input[type="time"]::-webkit-calendar-picker-indicator, .input.select::-webkit-calendar-picker-indicator {
      background-image: none;
    }
.input[type="date"]:hover,.input[type="date"]:active,.input[type="date"]:focus, .input[type="datetime-local"]:hover, .input[type="datetime-local"]:active, .input[type="datetime-local"]:focus, .input[type="time"]:hover, .input[type="time"]:active, .input[type="time"]:focus {
      background-image: none !important;
    }
.input[type="checkbox"], .input[type="radio"] {
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: polygon(0, 0, 0, 0, 0, 0);
    -webkit-clip-path: polygon(0, 0, 0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
.input[type="checkbox"] + .label, .input[type="radio"] + .label {
      display: inline-block;
      font-weight: 400;
      height: 24px;
      margin-left: calc(2 * 1.5rem);
      position: relative;
    }
.input[type="checkbox"] + .label::after, .input[type="radio"] + .label::after {
        background-color: #FEE199;
        box-sizing: border-box;
        content: '';
        display: inline-block;
        height: 14px;
        left: calc(-2 * 24px + 5px);
        position: absolute;
        top: 5px;
        transform: scale(0);
        transition: .2s cubic-bezier(.455, .03, .4, 1.645) 0s;
        width: 14px;
      }
.input[type="checkbox"] + .label::before, .input[type="radio"] + .label::before {
        border: 2px solid #DCDCDC;
        box-sizing: border-box;
        content: '';
        display: inline-block;
        position: absolute;
        height: 24px;
        left: calc(-2 * 24px);
        top: 0px;
        transition: .2s cubic-bezier(.455, .03, .4, 1.645) 0s;
        width: 24px;
      }
.input[type="checkbox"]:focus + .label::before, .input[type="checkbox"]:active + .label::before, .input[type="radio"]:focus + .label::before, .input[type="radio"]:active + .label::before {
        border-color: #FEE199;
      }
.input[type="checkbox"]:checked + .label::after, .input[type="radio"]:checked + .label::after {
          background-color: #FEE199;
          transform: scale(1);
        }
.label {
  color: #231F20;
  cursor: pointer;
  display: block;
  font-size: .875rem;
  font-weight: 600;
  text-transform: uppercase;
}
.leadership {
  display: grid;
  grid-gap: 1.125rem;
  grid-template-columns: repeat(12, 1fr);
}
.leadership__img {
    grid-column: 1/span 12;
  }
.leadership__content {
    font-size: 1.3125rem;
    grid-column: 1/span 12;
  }
.link, .richtext a {
  color: #231F20;
  text-decoration: underline;
  transition: .2s cubic-bezier(.455, .03, .4, 1.645) 0s;
}
.link:hover,.link:active,.link:focus, .richtext a:hover, .richtext a:active, .richtext a:focus {
    color: #957342;
    text-decoration: underline;
  }
.link--button, .link.button, .richtext a.button {
    min-width: 15rem;
    text-decoration: none;
    transition: .2s ease-in;
    user-select: none;
  }
.link--button:hover,.link--button:active,.link--button:focus, .link.button:hover, .link.button:active, .link.button:focus, .richtext a.button:hover, .richtext a.button:active, .richtext a.button:focus {
      color: #957342;
      text-decoration: none;
    }
.link.button--white, .richtext a.button--white {
    border-color: #FFFFFF;
    color: #FFFFFF;
  }
.link.button--white:hover,.link.button--white:active,.link.button--white:focus, .richtext a.button--white:hover, .richtext a.button--white:active, .richtext a.button--white:focus {
      border-color: var(--color-sup--3) !important;
      color: #A78658 !important;
    }
.link.button--white:hover::before,.link.button--white:active::before,.link.button--white:focus::before, .richtext a.button--white:hover::before, .richtext a.button--white:active::before, .richtext a.button--white:focus::before {
        border-color: var(--color-sup--3) !important;
        transition: border-color .4s cubic-bezier(.455, .03, .4, 1.645) 0s, left .2s cubic-bezier(.455, .03, .4, 1.645);
      }
.link--block, .link--next {
    display: block;
  }
.link--next {
    background-position: center right;
    background-repeat: no-repeat;
    padding: 1.5rem 0;
    overflow: hidden;
    width: 11.975rem;
  }
.link--next:hover,.link--next:active,.link--next:focus {
      margin-left: 1rem;
    }
.link--icon {
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    height: 21px;
    width: 27px;
  }
.link--icon.icon--twitter {
      background-image: url(../images/icons/icon-twitter.svg);
    }
.link--icon.icon--linkedin {
      background-image: url(../images/icons/icon-linkedin.svg);
    }
.link--icon.icon--facebook {
      background-image: url(../images/icons/icon-facebook.svg);
    }
.link--icon.icon--mail {
      background-image: url(../images/icons/icon-mail.svg);
    }
.list, .richtext ol, .richtext ul {
  list-style: unset;
  padding-left: 1.5em !important;
}
.list .li, .richtext ol .li, .richtext ul .li {
    margin: 0 0 .5em 0;
  }
.list--blank {
    list-style-type: none;
    padding-left: 0;
  }
.list--inline {
    list-style-type: none;
    padding-left: 0;
  }
.list--inline .li {
      display: inline-block;
      margin: 0 1em 0 0;
    }
.list--ordered, .richtext ol {
    list-style-type: decimal;
  }
.list--unordered, .richtext ul {
    list-style-type: disc;
  }
.mark {
  /* Mark */
}
.media-object {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
/* alignment variants */
.media-object--top { align-items: flex-start; }
.media-object--middle { align-items: center; }
.media-object--bottom { align-items: flex-end; }
/* sizing variants */
.media-object--2-3 > :first-child {
      flex-basis: 35%;
    }
.media-object--2-3 > :last-child {
      flex-basis: 60%;
    }
.media-object--3-2 > :first-child {
      flex-basis: 60%;
    }
.media-object--3-2 > :last-child {
      flex-basis: 35%;
    }
.media-object--50-50 > :first-child {
      flex-basis: 50%;
    }
.media-object--50-50 > :last-child {
      flex-basis: 50%;
    }
.media-object__figure {
    margin-bottom: 2rem;
    order: -10;
  }
.media-object--no-stack .media-object__figure {
      margin-bottom: 0;
      margin-top: 2rem;
      order: 0;
    }
/* figure alignment variants */
.media-object__figure--top { align-self: flex-start; }
.media-object__figure--middle { align-self: center; }
.media-object__figure--bottom { align-self: flex-end; }
/* body alignment variants */
.media-object__body--top { align-self: flex-start; }
.media-object__body--middle { align-self: center; }
.media-object__body--bottom { align-self: flex-end; }
.paragraph, .richtext p {
  display: block;
}
.paragraph--taller {
    line-height: 1.6;
  }
.picture__img {
    max-width: 100%;
    vertical-align: bottom;
  }
.picture__img--gif {
    max-width: 10.875rem;
  }
.picture--stretched .picture__img {
      width: 100%;
    }
.select {
  appearance: none;
  border-radius: 0;
  display: inline-block;
  font-weight: 400;
  padding-right: 2rem;
}
.social-share {
  align-items: flex-end;
  display: flex;
  flex-wrap: nowrap;
  justify-content: right;
  width: 100%;
}
.social-share .link + .link {
    margin-left: 1ch;
  }
.svg {
  /* Svg */
}
.table-wrapper {
  max-width: 100%;
  overflow-x: auto;
}
.table {
  width: 100%;
}
/* default styles */
.table__caption {
    caption-side: bottom;
    font-size: 1.5rem;
    font-weight: 600;
    padding: 1.5rem 0;
  }
.table__cell {
    border-bottom: 1px dotted var(--color-gray);
    padding: .5rem;
  }
.table__cell--header {
      font-weight: 600;
      text-align: left;
    }
.table--mobile-scroll .table__cell {
      /* This rule exists to better demonstrate the responsive properties */
      white-space: nowrap;
    }
.table--mobile-collapse {
    display: block;
    width: 100%;
  }
.table--mobile-collapse .table__caption, .table--mobile-collapse .table__body, .table--mobile-collapse .table__foot, .table--mobile-collapse .table__row {
      display: block;
    }
.table--mobile-collapse .table__head {
      display: none;
    }
.table--mobile-collapse .table__row:nth-child(even) {
        background-color: #F4F5F5;
      }
.table--mobile-collapse .table__cell {
      display: flex;
      justify-content: flex-end;
    }
.table--mobile-collapse .table__cell::before {
        content: attr(data-heading);
        flex: 1 1 auto;
        font-weight: 600;
        text-align: left;
      }
.textarea {
  background-image: repeating-linear-gradient(0deg, #888888, #888888 1px, transparent 1px, transparent);
  background-position: top 28px left 0;
  background-size: 1px 35px;
  border: none;
  font-family: 'Soehne', sans-serif;
  line-height: 1.575;
  max-width: none;
  min-height: 16rem;
  overflow: auto;
  resize: vertical;
  width: 100%;
}
.underline {
  background-image: linear-gradient(hsla(0,0%,100%,0),hsla(0,0%,100%,0)),linear-gradient(currentColor,currentColor);
  background-size: 100% 2px, 0 2px;
  background-position: 100% 100%, 0 90%;
  background-repeat: no-repeat;
  line-height: 1.05;
  cursor: pointer;
  transition: .2s cubic-bezier(.455, .03, .4, 1.645) 0s;
  transition-property: background;
  transition-duration: .35s;
}
.underline:hover,.underline:active,.underline:focus, .card:hover .underline, .card:active .underline, .card:focus .underline, .link:hover .underline, .link:active .underline, .link:focus .underline, .richtext a:hover .underline, .richtext a:active .underline, .richtext a:focus .underline {
    background-size: 0 2px,100% 2px;
    background-position: 100% 100%,0 90%;
  }
.wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 77.5rem;
  padding: 0 .75rem;
  position: relative;
  transition: padding .2s ease-in;
}
.wrapper--large {
    max-width: 100rem;
  }
.wrapper--medium {
    max-width: 90rem;
  }
.wrapper--small {
    max-width: 77.5rem;
  }
.wrapper--smaller {
    max-width: 66rem;
  }
.wrapper--smallest{
    max-width: 38rem;
  }
.wrapper .wrapper {
    padding-left: 0;
    padding-right: 0;
  }
.accordion {
  border-bottom: 1px solid #888888;
}
.accordion__head {
    border-bottom: 1px solid #888888;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 1.125rem;
    padding: 0 0 3rem;
  }
.accordion__title {
    grid-column: 1/span 12;
  }
.accordion__intro {
    font-size: 1.3125rem;
    grid-column: 1/span 12;
  }
.accordion__drawer {
    border-radius: 2px;
    margin-bottom: 0;
  }
.accordion__drawer .expandable__drawer {
      padding: 0 0 1rem 0;
    }
.accordion__group {
    margin: 1rem 0;
  }
.accordion__heading {
    cursor: pointer;
    display: grid;
    grid-gap: .5rem 1.125rem;
    grid-template-columns: repeat(12, 1fr);
    padding: 1.5rem 0;
    position: relative;
    text-transform: uppercase;
  }
.accordion__heading:focus {
      outline: none;
    }
.accordion__heading::after {
      background-color: #231F20;
      content: '';
      display: block;
      height: 2px;
      position: absolute;
      right: .75rem;
      top: 2.25rem;
      transform-origin: center;
      transition: .2s cubic-bezier(.455, .03, .4, 1.645) 0s;  
      width: 1.45rem;
    }
.accordion__heading::before {
      background-color: #231F20;
      content: '';
      display: block;
      height: 2px;
      position: absolute;
      right: .75rem;
      transform: rotate(90deg);
      transform-origin: center;
      transition: .2s cubic-bezier(.455, .03, .4, 1.645) 0s;
      top: 2.25rem;
      width: 1.45rem;
    }
.accordion__heading[aria-expanded="true"]::before {
        transform: rotate(135deg);
        content: '';
      }
.accordion__heading[aria-expanded="true"]::after {
        transform: rotate(45deg);
        content: '';
      }
.accordion__heading1 {
      font-weight: 600;
      grid-column: 1/span 10;
    }
.accordion__heading2 {
      grid-column: 1/span 11;
    }
.accordion__heading3 {
      grid-column: 1/span 11;
    }
.accordion__content {
    padding-bottom: 1.5rem;
  }
.accordion__group--numeric .accordion__layout {
    align-items: start;
    display: grid;
    grid-gap: 0 1.125rem;
    grid-template-columns: repeat(12, 1fr);
  }
.accordion__group--numeric .accordion__head {
    border: 0;
    grid-column: 1/span 12;
    grid-row: 1/span 99;
  }
.accordion__group--numeric .accordion__head .heading {
      margin-bottom: .25em;
    }
.accordion__group--numeric .accordion__head .link {
      color: #8A8587;
      font-size: 1.3125rem;
      margin-top: .25rem;
    }
.accordion__group--numeric .accordion__head .link:hover,.accordion__group--numeric .accordion__head .link:active,.accordion__group--numeric .accordion__head .link:focus {
        color: #957342;
      }
.accordion__group--numeric .accordion--numeric {
    margin-left: 1.5rem;
  }
.accordion__group--numeric .accordion--numeric .accordion__heading, .accordion__group--numeric .accordion--numeric .accordion__content {
      margin-left: -1.5rem;
    }
.accordion__group--numeric .accordion__title {
    grid-column: 1/span 12;
  }
.accordion__group--numeric .accordion__intro {
    grid-column: 1/span 12;
    padding: 0;
  }
.accordion__group--numeric .accordion__cta {
    font-size: 1.1325rem;
    grid-column: 1/span 12;
  }
.accordion__group--numeric .accordion__cta .link {
      color: #8a8587;
    }
.accordion__group--numeric .accordion__cta .link:hover,.accordion__group--numeric .accordion__cta .link:active,.accordion__group--numeric .accordion__cta .link:focus {
        color: #957342;
      }
.accordion__group--numeric .accordion {
    border-bottom: 1px solid #888888;
    border-top: 1px solid #888888;
    grid-column: 1/span 12;
    position: relative;
  }
.accordion__group--numeric .accordion + .accordion {
      border-top: 0;
    }
.accordion__group--numeric .accordion__heading0 {
    font-size: .875rem;
    font-weight: 400;
    grid-row: 0;
    left: -2rem;
    position: absolute;
    top: 1px;
  }
.accordion__group--numeric .accordion__heading1 {
    grid-column: 1/span 10;
    margin-left: 2rem;
    position: relative;
  }
.blog {
  background-color: #231F20;
  color: #FFFFFF;
}
.blog__title {
    margin: 3rem 0;
  }
.blog--light {
    background-color: #F4F5F5;
    color: #231F20;
  }
.blog__card {
    padding: 0;
    text-align: center;
    transition: .2s cubic-bezier(.455, .03, .4, 1.645) 0s;
  }
.blog__card + .blog__card {
      margin-top: 4rem;
    }
.blog--light .blog__card {
      color: #231F20;
    }
.blog__card-image {
    grid-column: 1/span 12;
    width: 100%;
  }
.blog__card-content {
    display: flex;
    flex-direction: column;
  }
.blog__card-content .link {
      color: unset;
      text-decoration: none;
    }
.blog__card-content .link:hover,.blog__card-content .link:active,.blog__card-content .link:focus {
        text-decoration: none;
      }
.blog__card-date {
    display: block;
    font-size: .875rem;
    margin: .75rem 0;
    grid-row: 1;
  }
.blog__card-link {
    order: 2;
  }
.blog__card-link .heading--h2 {
      font-size: 1.5rem;
      line-height: 130%;
    }
.blog__card-summary {
    order: 3;
    padding: 1.5rem 0;
  }
.blog__cta {
    margin-top: 8rem;
    text-align: center;
  }
/* Major variant */
.blog__card--major .blog__card-content {
      display: flex;
      flex-direction: column;
    }
.blog__card--major .blog__card-link .heading--h2 {
        font-size: 1.75rem;
      }
/* Reversed variant*/
.callout {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  text-align: center;
}
.callout .heading {
    grid-column: 1/span 12;
    font-family: 'SangBleuKingdom', serif;
    line-height: 1.5;
    font-size: calc(1.5rem + 0.625 * ((100vw - 26.25rem) / 53.75));
  }
.callout__attribution {
    grid-column: 1/span 12;
    font-size: 1.125rem;
    font-weight: 400;
    margin-top: 1.5rem;
  }
.callout--sans-serif .heading {
      font-family: 'Soehne', sans-serif;
    }
/*! Flickity v2.2.1
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative;
}
.flickity-enabled:focus { outline: none; }
.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}
.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}
/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}
.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
/* ---- flickity-button ---- */
.flickity-button {
  position: absolute;
  background: hsla(0, 0%, 100%, 0.75);
  border: none;
  color: #333;
}
.flickity-button:hover {
  background: white;
  cursor: pointer;
}
.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F;
}
.flickity-button:active {
  opacity: 0.6;
}
.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}
.flickity-button-icon {
  fill: currentColor;
}
/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}
.flickity-prev-next-button.previous { left: 10px; }
.flickity-prev-next-button.next { right: 10px; }
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}
.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}
/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}
.flickity-rtl .flickity-page-dots { direction: rtl; }
.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}
.flickity-page-dots .dot.is-selected {
  opacity: 1;
}
.carousel {
  position: relative;
  transition: .5s ease-in;
  z-index: 100;
}
.carousel__container {
    width: 100%;
  }
.carousel__cell {
    background-color: transparent;
    padding: 13.75rem 0 5rem;
    width: 100%;
    z-index: -1;
  }
.carousel__cell.is-selected {
      z-index: 1;
    }
.carousel__cell.is-selected .link {
        display: inline-block;
      }
.carousel__cell .image {
      width: 100%;
    }
.carousel__cell .link {
      display: none;
    }
.carousel__count {
    color: currentColor;
    display: block;
    left: .75rem;
    position: absolute;
    top: 7rem;
    z-index: 200;
  }
.carousel__title {
    color: #FFFFFF;
    font-size: 24px !important;
    left: .75rem;
    position: absolute;
    top: 4rem;
    z-index: 200;
  }
.carousel__controls {
    left: 100px;
    position: absolute;
    top: 6.5rem;
    z-index: 200;
  }
.carousel--basic .carousel__cell {
      padding: 6rem 0 3rem;
    }
.carousel--basic .carousel__title {
      color: #231F20;
      left: .75rem;
      top: 2.5rem;
    }
.carousel--basic .carousel__count, .carousel--basic .button--carousel-prev {
      display: none;
    }
.carousel--basic .button--carousel-next {
      margin: 0;
    }
.carousel--basic .carousel__controls {
      left: auto;
      right: .75rem;
      top: 2.25rem;
    }
/* Generated elements */
.flickity-viewport {
  transition: height .2s ease-in;
  overflow: hidden;
}
.cta-contact {
  background-color: #FFFFFF;
  overflow: hidden;
  padding: 5rem 0;
}
.cta-contact__layout {
    display: grid;
    grid-gap: 1.125rem;
    grid-template-columns: repeat(12, 1fr);
    position: relative;
  }
.cta-contact__content {
    grid-column: 1/span 12;
    margin-bottom: 25px;
    transition: .2s cubic-bezier(.455, .03, .4, 1.645) 0s;
  }
.cta-contact__cta {
    grid-column: 1/span 12;
    transition: .2s cubic-bezier(.455, .03, .4, 1.645) 0s;
  }
.cta-contact__sent-content {
    grid-column: 1/span 12;
  }
.cta-contact__legend {
    font-size: calc(1.5rem + 0.625 * ((100vw - 26.25rem) / 53.75));
    margin-bottom: 1.5rem;
  }
.cta-contact__text-inputs .input {
      line-height: 1.5;
      padding: 0;
    }
.cta-contact__textarea .button {
      float: right;
    }
.cta-contact p {
    font-size: calc(1.5rem + 0.625 * ((100vw - 26.25rem) / 53.75));
    line-height: 1.2;
  }
.cta-contact p + p {
      margin-top: 1rem;
    }
.cta-contact__step1, .cta-contact__step2, .cta-contact__step3 {
    left: 0;
    max-height: 0px;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    right: 0;
    transition: opacity 1s cubic-bezier(.165, .84, .44, 1);
  }
.cta-contact.step1 .cta-contact__step1 {
      max-height: 50rem;
      opacity: 1;
      pointer-events: all;
    }
.cta-contact.step2 .cta-contact__step2 {
      max-height: 50rem;
      opacity: 1;
      pointer-events: all;
    }
.cta-contact.step3 .cta-contact__step3 {
      max-height: 50rem;
      opacity: 1;
      pointer-events: all;
    }
.cta-contact .textarea {
    margin: 1.5rem 0;
    min-height: 8.35rem;
    max-width: 25rem;
  }
.cta-contact__newsletter {
    display: block;
    font-size: 1.25rem;
    margin-top: 2.5rem;
  }
.cta-contact .show-validation input:invalid {
    border-bottom: 1px solid #c2302c;
  }
.cta-newsletter {
  align-items: center;
  background-color: #F4F5F5;
  display: flex;
  justify-content: center;
  min-height: 5.3125rem;
}
.cta-newsletter .link {
    color: #231F20;
    transition-timing-function: ease-in;
  }
.cta-newsletter .link:hover,.cta-newsletter .link:active,.cta-newsletter .link:focus {
      color: #866841;
    }
.cta-newsletter--dark {
    background-color: #000000;
  }
.cta-newsletter--dark .link {
      color: #FFFFFF;
    }
.cta-newsletter--dark .link:hover,.cta-newsletter--dark .link:active,.cta-newsletter--dark .link:focus {
        color: #A78658;
      }
.expandable {
  overflow: hidden;
  transition: .2s cubic-bezier(.455, .03, .4, 1.645) 0s;
  transition-timing-function: ease-in;
}
.expandable:not(.is-expanded) .expandable__drawer {
      box-shadow: inset 0 -2px 5px 0 #F4F5F5;
    }
.global-footer {
  background-color: #231F20;
  overflow: auto;
  min-height: 100vh;
}
.global-header {
  background-color: transparent;
  display: flex;
  left: 0;
  padding: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: top .5s, background-color .5s;
  transition-duration: .5s;
  transition-delay: 0, .2s;
  /* transition-timing-function: var(--trans-function); */
  z-index: 200;
}
.global-header .wrapper {
    flex: 0 0 100%
  }
.global-header__layout {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    height: 3.5rem;
    width: 100%;
  }
.global-header__brand {
    grid-column: 1/span 8;
    transition: none;
  }
.global-header__brand svg {
      width: 5.625rem;
    }
.global-header__toggle {
    align-items: center;
    display: flex;
    grid-column: 12;
    justify-content: flex-end;
  }
.global-header__backdrop {
    bottom: 0;
    content: '';
    display: block;
    height: 100vh;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    transition: .2s cubic-bezier(.455, .03, .4, 1.645) 0s;
    transition-duration: .35s;
    width: 100vw;
    z-index: -1;
  }
.nav-open .global-header__backdrop {
      opacity: 1;
      pointer-events: all;
      z-index: -1;
    }
.global-header__navigation {
    min-height: 100vh;
  }
.global-header__navigation .navigation__layout {
      height: calc(100vh - 6.825rem);
      opacity: 0;
      overflow: auto;
      transform: scale(1.04);
      transition: .2s cubic-bezier(.455, .03, .4, 1.645) 0s;
    }
.nav-open .global-header__navigation .navigation__layout {
        opacity: 1;
        transform: scale(1);
        transition: .2s cubic-bezier(.455, .03, .4, 1.645) 0s;
      }
.scrolled-down .global-header {
    top: calc(0px - 3.5rem);
  }
.scrolled-up .global-header {
    background-color: rgba(0, 0, 0, .9);
    top: 0;
    transition-delay: .1s;
  }
.scrolled-up .global-header .global-header__brand svg path {
      fill: #FFFFFF;
    }
.scrolled-up .global-header .global-header__tagline {
      color: #FFFFFF;
    }
.scrolled-up .global-header .global-header__toggle .button::before, .scrolled-up .global-header .global-header__toggle .button::after {
      background-color: #FFFFFF;
    }
.icon-grid {
  background-color: #F4F5F5;
  padding-bottom: 3rem;
}
.icon-grid__layout {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 1.5rem;
  }
.icon-grid__heading {
    grid-column: 1/span 12;
  }
.icon-grid__intro {
    grid-column: 1/span 12;
  }
.icon-grid__intro p {
      font-size: calc(1rem + 0.3125 * ((100vw - 26.25rem) / 53.75));
    }
.icon-grid__cards {
    display: grid;
    flex-wrap: wrap;
    grid-column: 2/span 10;
    grid-gap: 20px 10px;
    grid-template-columns: 1fr 1fr;
    margin: 3rem 0;
  }
.icon-grid__card {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 15%;
  }
.input-checkbox {
  padding: calc(1.5rem / 2) 0;
}
.input-radio {
  padding: calc(1.5rem / 2) 0;
}
.latest-work {
  background-color: #F4F5F5;
  padding-top: 3rem;
}
.latest-work__layout {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 3rem 0;
  }
.latest-work__eyebrow {
    display: block;
    font-size: .75rem;
    line-height: 150%;
    margin-bottom: 12px;
  }
.latest-work__heading {
    font-size: 1.125rem;
    line-height: 127%;
  }
.latest-work__link {
    color: #231F20;
    display: block;
    text-decoration: none;
  }
.latest-work__link:hover,.latest-work__link:active,.latest-work__link:focus {
      text-decoration: underline;
    }
.latest-work__cta {
    padding: 0 0 3rem;
    text-align: right;
  }
.latest-work--dark {
    background-color: #231F20;
    color: #FFFFFF;
  }
.latest-work--dark .button {
      border-color: #FFFFFF;
      color: #FFFFFF;
    }
.latest-work__card {
  border: 0;
  color: var(--color-text);
  padding: 0 0 2.75rem 0;
  flex: 0 0 100%;
  gap: 1.25rem;
  margin-bottom: 1.5rem;
  text-decoration: none;
  transition: .2s cubic-bezier(.455, .03, .4, 1.645) 0s;
}
.latest-work__card .heading {
    font-size: 1.125rem;
  }
.latest-work__card.latest-work__card--super .heading, .latest-work__card.latest-work__card--major .heading {
      font-size: 1.5rem;
    }
.latest-work__card.latest-work__card--super {
    position: relative;
  }
.latest-work__card.latest-work__card--super .latest-work__card-image {
      position: relative;
      left: -1.5rem;
      right: -1.5rem;
      width: calc(100% + 3rem);
    }
.latest-work__card.latest-work__card--super .latest-work__card-content {
      background-color: #F4F5F5;
      margin: -5rem 1.5rem 0 1.5rem;
      padding: 1rem 1.25rem;
      position: relative;
    }
.latest-work--dark .latest-work__card.latest-work__card--super .latest-work__card-content {
        color: #231F20;
      }
.latest-work__card .latest-work__card-image {
    overflow: hidden;
  }
.latest-work__card .latest-work__card-image .picture__img {
      transform: scale(1.01);
      transition: .2s cubic-bezier(.455, .03, .4, 1.645) 0s;
      transition-duration: .35s;
      width: 100%;
    }
.latest-work__card:hover .latest-work__card-image .picture__img,.latest-work__card:active .latest-work__card-image .picture__img,.latest-work__card:focus .latest-work__card-image .picture__img {
        transform: scale(1.04);
      }
.marquee-article {
  background-color: #FFFFFF;
}
.marquee-article__layout {
    align-items: center;
    display: grid;
    grid-gap: 0 1.5rem;
    grid-template-columns: 1fr 1fr;
    padding: 3.5rem 0 3rem;
  }
.marquee-article__image {
    grid-column: 1/span 2;
    margin-top: 1.5rem;
  }
.marquee-article__image img {
      width: 100%;
    }
.marquee-article__heading {
    grid-column: 1/span 2;
    grid-row: 2;
    padding: 1.5rem 0;
  }
.marquee-article__type {
    grid-column: 1;
    grid-row: 3;
  }
.marquee-article__date {
    grid-column: 1/span 2;
    grid-row: 4;
  }
.marquee-article__author {
    grid-column: 1;
    grid-row: 5;
  }
.marquee-article__author + .marquee-article__share {
      grid-row: 4/span 2;
    }
.marquee-article__share {
    align-self: flex-end;
    grid-column: 2;
    grid-row: 3/span 2;
    text-align: right;
  }
.marquee-article__type, .marquee-article__date, .marquee-article__author {
    font-size: .875rem;
    line-height: 1.5rem;
  }
.marquee-major {
  position: relative;
}
.marquee-major__layout {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 1.125rem;
  }
.marquee-major__heading {
    grid-column: 1/span 12;
  }
.marquee-major__heading .heading {
      transition: font-size .2s linear;
    }
.marquee-major__heading .richtext {
      font-size: 1.25rem;
      margin: 1.5rem 0;
      transition: .2s cubic-bezier(.455, .03, .4, 1.645) 0s;
      transition-timing-function: ease-in;
    }
.marquee-major__intro {
    grid-column: 1/span 12;
  }
.marquee-major__images {
    grid-column: 1/span 12;
    position: relative;
    z-index: 2;
  }
.marquee-major__images .image {
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
.marquee-major__images[data-step="step-1"] .image:nth-child(1) {
        opacity: 1;
      }
.marquee-major__images[data-step="step-2"] .image:nth-child(2) {
        opacity: 1;
      }
.marquee-major__images[data-step="step-3"] .image:nth-child(3) {
        opacity: 1;
      }
.marquee-major__images[data-step="step-4"] .image:nth-child(4) {
        opacity: 1;
      }
.marquee-major__images[data-step="step-5"] .image:nth-child(5) {
        opacity: 1;
      }
.marquee-major__images[data-step="step-6"] .image:nth-child(6) {
        opacity: 1;
      }
.marquee-major__ending {
    color: #FFFFFF;
    grid-column: 1/span 12;
    position: relative;
    z-index: 1;
  }
.marquee-major__tagline {
    font-size: calc(1.75rem + 0.75 * ((100vw - 26.25rem) / 53.75));
    position: relative;
  }
.marquee-major__tagline.active {
      top: 0;
      opacity: 1;
    }
.marquee-major::before {
    background-color: #231F20;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    height: 55%;
  }
.marquee-major--alt {
  padding-bottom: 0;
}
.marquee-major--alt::before {
    background-color: #F4F5F5;
    height: 45%;
    content: '';
  }
.marquee-major--alt .marquee-major__ending {
    padding: 18rem 0 3rem;
  }
.marquee-super {
  background-color: #FFFFFF;
  padding: 5.5rem 0 0;
}
.marquee-super__layout {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
.marquee-super .heading {
    grid-column: 1/span 12;
    grid-row: 2;
    line-height: 90%;
    margin-bottom: -.65rem;
  }
.marquee-super .richtext {
    font-size: 1rem;
    grid-column: 2/span 10;
    grid-row: 1;
    margin-bottom: 3rem;
  }
.marquee-super .video {
    background-color: #231F20;
    border: 10px solid #FFFFFF;
    grid-column: 1/span 12;
    grid-row: 3;
    width: 100%;
  }
.marquee-super::after {
    background-color: #F4F5F5;
    content: '';
    display: block;
    height: 8rem;
    margin-top: -6rem;
    width: 100%;
  }
.marquee-two-column {
  padding: 8rem 0 3rem;
}
.marquee-two-column__layout {
    align-items: center;
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 1.125rem 1fr;
  }
.marquee-two-column__heading {
    align-self: end;
    grid-column: 1/span 12;
    grid-row: 1;
  }
.marquee-two-column__content {
    align-self: start;
    grid-column: 1/span 12;
    grid-row: 2;
    font-size: calc(1.125rem + 0.1875 * ((100vw - 26.25rem) / 53.75));
    padding-bottom: 1.5rem;
    max-width: 100%;
  }
.marquee-two-column__form {
    background-color: #FFFFFF;
    grid-column: 1/span 12;
    grid-row: 3;
    padding: 1.5rem;

  }
.marquee-two-column__form .button {
      background-image: url(../images/icons/subscribe-button.svg);
      color: #757575;
      float: right;
      margin: 3rem 0 0 0;
      padding-right: 7rem;
    }
.marquee-two-column__form .button:hover,.marquee-two-column__form .button:active,.marquee-two-column__form .button:focus {
        color: #866841;
      }
.marquee-two-column__form .feedback-summary .error{
        color: #c2302c;
      }
.marquee-two-column__form .hidden{
      display: none;
    }
.marquee {
  background-color: #FFFFFF;
  padding: 8rem 0 3rem;
}
.marquee__layout {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(12, 1fr);
  }
.marquee__heading {
    grid-column: 1/span 12;
    grid-row: 1;
    font-size: calc(2rem + 2.375 * ((100vw - 26.25rem) / 53.75));
  }
.marquee__content {
    grid-column: 1/span 12;
    grid-row: 2;
    font-size: calc(1.125rem + 0.5 * ((100vw - 26.25rem) / 53.75));
    padding-bottom: 3rem;
  }
.modal {
  --modal-icon-color: currentColor;
  transition: .2s cubic-bezier(.455, .03, .4, 1.645) 0s;
}
.modal__close {
    align-items: center;
    background-color: transparent;
    border: none;
    color: var(--color-red);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    font-size: 1rem;
    height: 3rem;
    justify-content: flex-end;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: calc(400 + 1);
  }
.modal__close:hover,.modal__close:active,.modal__close:focus {
      color: #FFFFFF;
    }
.modal__close-icon {
      height: auto;
      margin: 1.5ch 0 3ch;
      width: 1rem;
    }
.modal__dialog {
    background-color: transparent;
    border: none;
    color: #FFFFFF;
    left: 1rem;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 3rem 0;
    position: fixed;
    top: 8rem;
    width: calc(100vw - 2rem);
    z-index: calc(400 + 1);
  }
.modal__dialog[open] {
      display: block;
    }
.modal__overlay {
    background-color: #FEE199;
    bottom: 0;
    cursor: pointer;
    height: 100vh;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100vw;
    z-index: 400;
  }
.modal--signin .modal__overlay {
      opacity: .5;
    }
.modal--signin .modal__close {
      display: none;
    }
.modal--signin .modal__dialog {
      bottom: 0;
      border-radius: 25px;
      color: #231F20;
      height: auto;
      left: 0;
      margin: 0 auto;
      max-height: 100vh;
      overflow: auto;
      padding: 0;
      right: 0;
      top: 2.5rem;
      width: calc(100% - 3rem);
    }
.modal--alternate .modal__close {
      right: 1.75rem;
      top: 1rem;
    }
.modal--alternate .modal__dialog {
      background-color: #FFFFFF;
      border-radius: 25px;
      color: #231F20;
      padding: 1.5rem;
      top: 1.5rem;
    }
.modal[aria-hidden='true'] {
  display: none;
}
[data-a11y-dialog-native] > .modal__overlay {
  display: none;
}
.modal__dialog[open] + &__overlay {
  display: block;
}
#signin-modal .modal__overlay {
    opacity: 0;
  }
.navigation {
  background-color: #231F20;
  color: #FFFFFF;
  padding: 6rem 0 3rem;
  overflow: auto;
}
.navigation .link {
    color: #FFFFFF;
  }
.navigation .link:hover,.navigation .link:active,.navigation .link:focus {
      color: #FFFFFF;
    }
.navigation__nav {
    display: flex;
    flex-direction: column;
  }
.navigation__layout {
    display: grid;
    grid-gap: 6rem;
  }
.navigation__nav-item {
    align-self: start;
    font-size: calc(2.5rem + 0.625 * ((100vw - 26.25rem) / 53.75));
    text-decoration: none;
  }
.navigation__nav-item:hover,.navigation__nav-item:active,.navigation__nav-item:focus {
      text-decoration: none;
    }
.navigation__contact {
    font-size: calc(1rem + 0.375 * ((100vw - 26.25rem) / 53.75));
    font-weight: 300;
  }
.navigation__contact .heading {
      font-size: calc(.875rem + 0.5 * ((100vw - 26.25rem) / 53.75));
      font-weight: 300;
    }
.navigation__contact .link {
      text-decoration: underline;
      transition-timing-function: ease-in;
    }
.navigation__contact .link:hover,.navigation__contact .link:active,.navigation__contact .link:focus {
        color: #A78658;
      }
.navigation__copy {
    font-size: calc(.875rem + 0.125 * ((100vw - 26.25rem) / 53.75));
    margin-top: 5rem;
  }
.next-article {
  margin-top: 3rem;
  position: relative;
}
.next-article__image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    grid-column: 1/span 12;
  }
.next-article__image .image {
      vertical-align: baseline;
    }
.next-article__content {
    align-items: center;
    color: #EF5D3C;
    display: flex;
    flex-direction: column;
    grid-column: 1/span 12;
    justify-content: space-between;
    padding: 3rem 1.5rem 1.5rem;
    text-align: center;
    transition: padding .2s linear;
  }
.next-article__content i {
      font-size: calc(.75rem + 0.25 * ((100vw - 26.25rem) / 53.75));
    }
.next-article__content .heading {
      line-height: 1.3;
    }
.next-article__date {
    color: #91AABA;
    display: block;
  }
.next-article__link {
    display: block;
  }
.next-article__link svg {
      fill: currentColor;
    }
.next-article .link {
    color: currentColor;
    text-decoration: none;
  }
.next-article::before {
    background-color: #F4F5F5;
    bottom: 3rem;
    content: '';
    display: block;
    height: 66%;
    left: 0;
    position: absolute;
    right: 0;
  }
.pagination__layout {
    align-items: center;
    display: flex;
    justify-content: space-around;
  }
.pagination .list {
    display: flex;
  
  }
.panel-content {
  align-items: center;
  background-color: transparent!important;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
.panel-content__heading {
    font-family: 'SangBleuKingdom', serif;
    grid-column: 1/span 12;
    transition: .2s cubic-bezier(.455, .03, .4, 1.645) 0s;
  }
.carousel .panel-content__heading {
      color: #FFFFFF;
    }
.panel-content__content {
    color: inherit;
    font-size: 1.3125rem;
    grid-column: 1/span 12;
    line-height: 1.5;
    margin: 2.25rem 0 0;
    max-width: 691px;
  }
.panel-content__content .link {
      color: inherit;
      display: block;
      font-size: 1.3125rem;
      margin-top: 1rem;
    }
.panel-content__heading, .panel-content__content {
    opacity: 0;
    transition: .2s cubic-bezier(.455, .03, .4, 1.645) 0s;
    transition-duration: .25s;
    transition-delay: .125s;
  }
.is-selected .panel-content__heading, .is-selected .panel-content__content {
      opacity: 1;
    }
.parallax-graphic {
  padding: 3rem 0 0;
  position: relative;
}
.parallax-graphic__mobile-img {
    margin: auto;
    max-width: 60%;
    text-align: center;
  }
.parallax-graphic__mobile-img .image {
      margin: 1.5rem 0 3rem 0;
    }
.parallax-graphic__img {
    display: none;
  }
.picture-inline__layout {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(12, 1fr);
  }
.picture-inline .figure {
    grid-column: 1/span 12;
  }
.picture-inline .picture, .picture-inline img {
    display: block;
    width: 100%;
  }
.picture-inline .picture + .picture {
      margin-top: 1.5rem;
    }
.richtext--prominent {
    font-size: calc(1.5rem + 0.25 * ((100vw - 26.25rem) / 53.75));
  }
.richtext--large {
    font-size: calc(1.25rem + 0.0625 * ((100vw - 26.25rem) / 53.75))
  }
.richtext--default {
    font-size: 1rem;
  }
.richtext--small {
    font-size: .8125rem;
  }
.richtext--desc {
    padding: 0;
  }
.richtext--desc + .richtext--desc {
      padding: 3rem 0 0 0;
    }
.richtext--fullwidth {
    padding: 0;
  }
.richtext--fullwidth ul, .richtext--fullwidth ol {
      margin: .25rem 0 0 2rem;
    }
.richtext__layout {
    display: grid;
    grid-gap: 0 1.125rem;
    grid-template-columns: repeat(12, 1fr);
  }
.richtext__layout > * {
      grid-column: 1/span 12;
    }
.richtext--fullwidth .richtext__layout > * {
        grid-column: 1/span 10;
        padding-left: 2rem;
      }
/* common RTE elements */
.richtext strong {
    font-weight: 600;
  }
.richtext em {
    font-style: italic;
  }
.richtext li {
    margin: 0 0 .125rem 0;
  }
.richtext sub, .richtext sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
.richtext sup {
    /* Move the superscripted text up */
    top: -.5em;
  }
.richtext sub {
    /* Move the subscripted text down, but only
        half as far down as the superscript moved up */
    bottom: -.25em;
  }
.select-clients {
  opacity: 0;
}
.is-selected .select-clients {
    opacity: 1;
  }
.select-clients__layout {
    align-items: center;
    display: grid;
    grid-gap: 1.125rem;
    grid-template-columns: repeat(12, 1fr);
  }
.select-clients__title {
    grid-column: 1/span 6;
    grid-row: 1;
  }
.select-clients__client {
    font-weight: 600;
  }
.select-clients__client a {
      color: inherit;
      padding: .5rem 0;
      text-decoration: underline;
    }
.select-clients__client a:hover,.select-clients__client a:active,.select-clients__client a:focus {
        color: #A78658;
      }
.select-clients__figure {
    grid-column: 1/span 12;
    grid-row: 2;
    margin: 1.5rem 0;
    z-index: 1;
  }
.select-clients__img {
    display: none;
  }
.select-clients__img .image {
      display: block;
    }
.carousel__cell .select-clients__img {
      opacity: 0;
      transform: translate(60px, 0);
    }
.carousel__cell.is-selected .select-clients__img {
      opacity: 1;
      transform: translate(0, 0);
      transition: transform .75s ease-out;
    }
.select-clients__quote {
    background-color: #FFFFFF;
    font-size: calc(1.125rem + 0.2250000000000001 * ((100vw - 26.25rem) / 53.75));

    padding: 2.5rem 0;
  }
.select-clients__cite {
    align-items: center;
    display: flex;
  }
.select-clients__cite .image {
      border-radius: 50%;
      margin-right: .625rem;
      max-width: 3.125rem;
    }
.split-media__layout {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(12, 1fr);
    justify-items: center;
  }
.split-media__content {
    border-top: 3px solid #231F20;
    font-size: 1.125rem;
    grid-column: 1/span 12;
    padding-top: 1.5rem;
  }
.split-media__image {
    grid-column: 1/span 12;
    width: 100%;
  }
.split-media__image img {
      width: 100%;
    }
.tabs {
  max-width: 100%;
}
.tabs__tab-list {
    display: flex;
    justify-content: center;
    text-align: center;
  }
.tabs__tab {
    border: 0;
    background: none;
    color: #231F20;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    padding: 1rem 2rem;
    position: relative;
    text-transform: uppercase;
  }
.tabs__tab:focus {
      outline: none;
    }
.tabs__tab:hover,.tabs__tab:active,.tabs__tab:focus {
      background-color: #DCDCDC;
    }
.tabs__tab::after {
      background-color: #FEE199;
      content: '';
      display: block;
      height: 4px;
      left: 0;
      max-width: 0;
      position: absolute;
      top: 100%;
      transition: .2s cubic-bezier(.455, .03, .4, 1.645) 0s;
      width: 100%;
      z-index: 100;
    }
.tabs__tab.is-active::after {
      max-width: 100%;
      content: '';
    }
.tabs__panel-list {
    max-width: 100%;
    padding: 1.5rem;
  }
.tabs__panel {
    max-width: 100%;
  }
.testimonial {
  padding: 1rem 0;
}
.testimonial .blockquote {
    padding-left: 0;
    padding-right: 0;
  }
.testimonial + .testimonial {
    border-top: 1px solid #F4F5F5;
  }
.text-with-cta {
  background-color: #231F20;
  color: #FFFFFF;
  font-size: calc(1.25rem + 0.125 * ((100vw - 26.25rem) / 53.75));
}
.text-with-cta__layout {
    display: grid;
    grid-gap: 1.125rem;
    grid-template-columns: repeat(12, 1fr);
  }
.text-with-cta__primary, .text-with-cta__secondary, .text-with-cta__cta {
    grid-column: 1/span 12;
    margin-bottom: 1.5rem;
  }
.text-with-cta__cta {
    text-align: left;
  }
.three-col-deck {
  padding: 3rem 0;
}
.three-col-deck__layout {
    display: grid;
    grid-gap: 6rem;
  }
.three-col-deck__card {
    padding: 0;
  }
.three-col-deck .richtext {
    margin: 1.5rem 0 1rem;
  }
.three-col-deck a {
    color: inherit;
  }
.umbraco-form .turnstyle-grid--1up, .umbraco-form .turnstyle-grid--2up, .umbraco-form .turnstyle-grid--3up, .umbraco-form {
    display: grid;
    grid-template-columns: 1fr;
  }
.video-background {
  /* VideoBackground */
}
.grid {
  display: grid;
  grid-gap: 1.5rem;
  margin: 3rem 0;
}
.pageshell {
  margin: 0 auto;
  overflow-x: clip;
}
.pageshell__main {
    background-color: #FFFFFF;
    position: relative;
  }
.about {
  /* About */
}
.article {
  /* Article */
}
.careers {
  /* Careers */
}
.contact {
  /* Contact */
}
.home {
  /* Home */
}
.insights {
  /* Insights */
}
.newsletter {
  /* Newsletter */
}
.services {
  /* Services */
}
.sg-definition {
  border-top: 1px solid gray;
  grid-gap: 1.5rem;
  padding: 1.5rem 0;
}
.sg-definition__label {
    font-weight: bold;
  }
.sg-definition--body--grid > * {
        margin-top: 1.5rem;
      }
.sg-definition--heading {
    display: grid;
    grid-template-columns: 1fr;
  }
.sg-definition--heading .sg-h1-is-mobile {
      font-size: 2rem !important;
    }
.sg-definition--heading .sg-h2-is-mobile {
      font-size: 2rem !important;
    }
.sg-definition--heading .sg-h3-is-mobile {
      font-size: 1.75rem !important;
    }
.sg-definition--heading .sg-h4-is-mobile {
      font-size: 1.5rem !important;
    }
.sg-definition--heading .sg-h5-is-mobile {
      font-size: 1.25rem !important;
    }
.sg-definition--heading .sg-h6-is-mobile {
      font-size: 1rem !important;
    }
.sg-definition--palette {
    padding: 1.5rem;
  }
.sg-definition--palette .grid > * {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
.sg-definition--palette__card {
      border: 0;
      padding: 0;
    }
.sg-definition--palette__card .card {
        border: 0;
        border-bottom: 1px solid #F4F5F5;
        padding: 0 0 66.666% 0;
      }
.sg-definition--palette__card .dl {
        font-size: .7rem;
      }
.sg-definition--palette__card .figure .figcaption {
          padding: 1.5rem;
        }
.work {
  /* Work */
}
.button--default::before {
	content: '';
}
.button.link::before {
	content: '';
}
.button--default:hover::before {
	content: '';
}
.button.link:hover::before {
	content: '';
}
.button--default:active::before {
	content: '';
}
.button.link:active::before {
	content: '';
}
.button--default[disabled]::before {
	content: '';
}
.button.link[disabled]::before {
	content: '';
}
.button--default.button--white:hover::before {
	content: '';
}
.button--default.button--white:active::before {
	content: '';
}
.button.link.button--white:hover::before {
	content: '';
}
.button.link.button--white:active::before {
	content: '';
}
.button--burger:hover::before {
	content: '';
}
.button--burger:active::before {
	content: '';
}
.button--burger:hover::after {
	content: '';
}
.button--burger:active::after {
	content: '';
}
.input[type="checkbox"]:focus + .label::before {
	content: '';
}
.input[type="checkbox"]:active + .label::before {
	content: '';
}
.input[type="radio"]:focus + .label::before {
	content: '';
}
.input[type="radio"]:active + .label::before {
	content: '';
}
.input[type="checkbox"]:checked + .label::after {
	content: '';
}
.input[type="radio"]:checked + .label::after {
	content: '';
}
.link.button--white:hover::before {
	content: '';
}
.link.button--white:active::before {
	content: '';
}
.link.button--white:focus::before {
	content: '';
}
.richtext a.button--white:hover::before {
	content: '';
}
.richtext a.button--white:active::before {
	content: '';
}
.richtext a.button--white:focus::before {
	content: '';
}
.scrolled-up .global-header .global-header__toggle .button::before {
	content: '';
}
.scrolled-up .global-header .global-header__toggle .button::after {
	content: '';
}
@media only screen and (min-width: 32rem) {
.accordion__heading1 {
        grid-column: 1/span 4
    }
.accordion__heading2 {
        grid-column: 5/span 4
    }
.accordion__heading3 {
        grid-column: 9/span 3
    }
.cta-contact__newsletter {
      font-size: 1.625rem
  }
.modal--signin .modal__dialog {
        right: 1.5rem
    }
      }
@media only screen and (min-width: 40rem) {
.modal__dialog {
      left: calc(50% - 40vw);
      max-height: calc(100vh - 2rem);
      max-width: 80vw;
      top: 2rem
  }
.tabs--vertical, .tabs--vert {
      align-content: flex-start;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start
  }
.tabs--vertical .tabs__tab-list, .tabs-vert .tabs__tab-list {
        flex-direction: column;
        justify-content: flex-start;
        max-width: 200px;
        width: 15%
    }

        .tabs--vertical .tabs__tab-list + .tabs__panel-list, .tabs-vert .tabs__tab-list + .tabs__panel-list {
          border-left: 1px solid #DCDCDC;
        }
.tabs--vertical .tabs__tab, .tabs-vert .tabs__tab {
        padding: 2rem 1rem
    }
.tabs--vertical .tabs__panel-list, .tabs-vert .tabs__panel-list {
        width: calc(100% - 200px)
    }

        .tabs--vertical .tabs__panel-list + .tabs__tab-list, .tabs-vert .tabs__panel-list + .tabs__tab-list {
          border-left: 1px solid #DCDCDC;
        }
    }
@media only screen and (min-width: 48rem) {
.blockquote, blockquote {
    font-size: 1.625rem;
    line-height: 39px
}
.button {
    height: 4.5rem;
    font-size: 1.25rem;
    min-width: 14rem;
    padding: 0 2.25rem
}
.button--default::before, .button.link::before {
        width: 66px;
        left: -2.5rem
    }
.button--default:hover::before, .button.link:hover::before {
          left: calc(100% - 1.75rem)
      }
.button--burger {
      height: 60px;
      min-width: 0;
      padding: 0;
      width: 48px
  }
.button--burger::before, .button--burger::after {
        left: 14px;
        width: 32px
    }
.button--burger::before {
        top: 22px;
        content: ''
    }
.button--burger::after {
        top: 36px;
        content: ''
    }
.button--burger.active::before {
          top: 28px;
          content: ''
      }
.button--burger.active::after {
          top: 28px;
          content: ''
      }
.button--carousel {
      height: 2.5rem;
      margin: 0;
      min-width: 0;
      padding: 0;
      width: 5.375rem
  }
.form__item {
      padding: 1.5rem 0
  }
.input {
    font-size: 1.625rem
}
.leadership__img {
      grid-column: 1/span 3
  }
.leadership__content {
      grid-column: 5/span 7
  }
.link--icon {
      height: 26px;
      width: 34px
  }
.media-object {
    flex-direction: row
}
.media-object > :first-child {
      margin-right: 2rem
  }
.media-object__figure {
      margin-bottom: 0;
      order: 0
  }
.media-object--no-stack .media-object__figure {
        margin-top: 0
    }
.picture__img--gif {
      max-width: 100%
  }
.table__caption {
      caption-side: top
  }
.table--mobile-collapse {
      display: table
  }
.table--mobile-collapse .table__head {
        display: table-header-group
    }
.table--mobile-collapse .table__caption {
        display: table-caption
    }
.table--mobile-collapse .table__body {
        display: table-row-group
    }
.table--mobile-collapse .table__foot {
        display: table-row-group
    }
.table--mobile-collapse .table__row {
        display: table-row
    }
.table--mobile-collapse .table__cell {
        display: table-cell
    }
.table--mobile-collapse .table__cell::before {
          content: ''
      }
.textarea {
    background-position: top 41px left 0;
    background-size: 1px 44px;
    line-height: 1.68
}
.wrapper {
    padding: 0 1.5rem
}
.accordion__head {
      padding: 0 0 4.25rem
  }
.accordion__intro {
      grid-column: 5/span 6;
      padding: 1.5rem 3rem 0 0
  }
.accordion__group {
      margin: 5rem 0
  }
.accordion__heading {
      padding: 2rem 0
  }
.accordion__heading::after {
        right: 1.5rem;
        top: 2.75rem;
        content: ''
    }
.accordion__heading::before {
        right: 1.5rem;
        top: 2.75rem;
        content: ''
    }
.accordion__group--numeric .accordion__head {
      grid-column: 1/span 4;
      padding-right: 3rem
  }
.accordion__group--numeric .accordion--numeric {
      margin-left: 0
  }
.accordion__group--numeric .accordion--numeric .accordion__heading, .accordion__group--numeric .accordion--numeric .accordion__content {
        margin-left: 0
    }
.accordion__group--numeric .accordion {
      grid-column: 5/span 8
  }
.accordion__group--numeric .accordion__heading1 {
      margin-left: 0
  }
.blog__title {
      margin: 3rem 0 4.5rem
  }
.blog--light .blog__title {
        font-size: 50px
    }
.blog__card {
      align-items: start;
      display: grid;
      grid-gap: 1.125rem;
      grid-template-columns: repeat(12, 1fr);
      text-align: left
  }
.blog__card-image {
      grid-column: 1/span 3
  }
.blog__card-content {
      display: grid;
      grid-template-columns: 1fr;
      grid-column: 5/span 8
  }
.blog__card-date {
      color: #91AABA;
      font-size: 1rem;
      grid-row: 2
  }
.blog__card-link {
      order: 1
  }
.blog__card-link .heading--h2 {
        font-size: 2rem;
        line-height: 120%
    }
.blog__card-summary {
      font-size: 1.125rem
  }
.blog__cta {
      margin: 8rem 0 5rem;
      text-align: right
  }
.blog__card--major .blog__card-image {
        grid-column: 1/span 6
    }
.blog__card--major .blog__card-content {
        grid-column: 7/span 6;
        padding: 0 3rem;
        text-align: center
    }
.blog__card--major .blog__card-summary {
        flex: 0 0 100%;
        font-size: 1.3125rem;
        padding: 1.5rem 0
    }
.blog__card--major .blog__card-link {
        flex: 0 0 100%;
        order: 1
    }
.blog__card--major .blog__card-link .heading--h2 {
          font-size: 2.5rem
      }
.blog__card--major .blog__card-date {
        flex: 0 0 100%;
        order: 2
    }
.blog__card--reversed .blog__card-image {
        grid-column: 7/span 6;
        grid-row: 1
    }
.blog__card--reversed .blog__card-content {
        grid-column: 1/span 6;
        grid-row: 1;
        padding: 0 3rem
    }
.callout .heading {
      grid-column: 3/span 8
  }
.carousel__cell {
      padding: 24.75rem 0 12rem
  }
.carousel__count {
      left: 3rem;
      top: 15.25rem
  }
.carousel__title {
      font-size: 34px !important;
      left: 3rem;
      line-height: 41px
  }
.carousel__controls {
      left: 158px;
      top: 14.875rem
  }
.carousel--basic .carousel__cell {
        padding: 13rem 0 6rem
    }
.carousel--basic .carousel__title {
        left: 1.5rem;
        top: 4rem
    }
.carousel--basic .carousel__controls {
        left: 1.5rem;
        top: 7rem
    }
.cta-contact {
    padding: 10rem 0;
    text-align: left
}
.cta-contact__content {
      grid-column: 1/span 6;
      margin-bottom: 0
  }
.cta-contact__cta {
      grid-column: 8/span 5
  }
.cta-contact__form-layout {
      display: grid;
      grid-gap: 1.125rem;
      grid-template-columns: repeat(12, 1fr)
  }
.cta-contact__sent-layout {
      display: grid;
      grid-gap: 1.125rem;
      grid-template-columns: repeat(12, 1fr)
  }
.cta-contact__sent-content {
      grid-column: 1/span 12
  }
.cta-contact__legend {
      grid-column: 1/span 12
  }
.cta-contact__text-inputs {
      grid-column: 1/span 6
  }
.cta-contact__textarea {
      grid-column: 7/span 6
  }
.cta-contact .textarea {
      margin: 0;
      min-height: 16rem
  }
.global-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    min-height: 0;
    right: 0;
    z-index: 1
}
.global-header__layout {
      grid-gap: 1.5rem;
      height: 6.825rem
  }
.global-header__brand {
      grid-column: 1/span 2
  }
.global-header__brand svg {
        width: 8.0625rem
    }
.scrolled-down .global-header {
      top: calc(0px - 6.825rem)
  }
.icon-grid {
    padding: 5rem 0 8rem
}
.icon-grid__heading {
      font-size: 34px !important
  }
.icon-grid__intro {
      grid-column: 2/span 10
  }
.icon-grid__cards {
      grid-template-columns: 1fr 1fr 1fr 1fr
  }
.icon-grid--sans-heading .icon-grid__intro {
        grid-column: 2/span 6
    }
.latest-work {
    padding-top: 4.9rem
}
.latest-work__layout {
      margin: 4.5rem 0
  }
.latest-work__eyebrow {
      font-size: 1rem
  }
.latest-work__heading {
      font-size: 1.5rem
  }
.latest-work--major .latest-work__heading, .latest-work--super .latest-work__heading {
        font-size: 1.5rem
    }
.latest-work__cta {
      padding-bottom: 6rem
  }
.latest-work__card {
    flex: 1 1 calc(31%)
}
.latest-work__card .heading {
      font-size: 1.4rem
  }
.latest-work__card.latest-work__card--super, .latest-work__card.latest-work__card--major {
      flex: 0 0 66%;
      flex-direction: row;
      margin-bottom: 0;
      margin-left: auto;
      padding: 0 0 0 0
  }
.latest-work__card.latest-work__card--super .latest-work__card-image, .latest-work__card.latest-work__card--super .latest-work__card-content, .latest-work__card.latest-work__card--major .latest-work__card-image, .latest-work__card.latest-work__card--major .latest-work__card-content {
        align-items: start;
        flex: 1 1 50%
    }
.latest-work__card.latest-work__card--super .latest-work__card-content, .latest-work__card.latest-work__card--major .latest-work__card-content {
        border-top: solid 3px;
        margin-top: 0;
        padding: 1.5rem 1.5rem 0 0
    }
.latest-work__card.latest-work__card--super .heading, .latest-work__card.latest-work__card--major .heading {
        font-size: 1.625rem
    }
.latest-work__card.latest-work__card--super {
      flex: 0 0 100%
  }
.latest-work__card.latest-work__card--super .latest-work__card-image {
        flex: 1 1 67.25%;
        left: unset;
        right: unset;
        width: 100%
    }
.latest-work--dark .latest-work__card.latest-work__card--super .latest-work__card-content {
          color: inherit
      }
.latest-work__card.latest-work__card--super .latest-work__card-content {
        background-color: unset;
        flex: 1 1 32.75%;
        margin: 0;
        position: initial
    }
.latest-work__card--reversed.latest-work__card--super .latest-work__card-image {
        flex: 1 1 67%;
        order: 2
    }
.latest-work__card--reversed.latest-work__card--super .latest-work__card-content {
        flex: 1 1 33%;
        order: 1
    }
.latest-work__card--reversed.latest-work__card--major .latest-work__card-image {
        order: 2
    }
.latest-work__card--reversed.latest-work__card--major .latest-work__card-content {
        order: 1
    }
.marquee-article__layout {
      align-items: start;
      grid-template-columns: repeat(12, 1fr);
      padding: 6.825rem 0 1.5rem
  }
.marquee-article__image {
      grid-column: 1/span 6;
      margin-top: 0
  }
.marquee-article__heading {
      grid-column: 7/span 5;
      grid-row: 1;
      padding: 0
  }
.marquee-article__date {
      grid-column: 3/span 5
  }
.marquee-article__author {
      grid-column: 7/span 3
  }
.marquee-article__author + .marquee-article__share {
        grid-column: 10/span 3
    }
.marquee-article__share {
      grid-row: 2!important;
      grid-column: 10/span 3;
      padding-top: 2rem !important
  }
.marquee-article__type, .marquee-article__date, .marquee-article__author {
      font-size: 1rem
  }
.marquee-article__type, .marquee-article__date, .marquee-article__author, .marquee-article__share {
      padding-top: 3rem;
      grid-row: 2
  }
.marquee-major__heading {
      grid-column: 1/span 5;
      padding-right: 3rem
  }
.marquee-major__heading .heading {
        margin-bottom: 1.5rem
    }
.marquee-major__heading .richtext {
        font-size: 1.25rem
    }
.marquee-major__intro {
      grid-column: 2/span 4
  }
.marquee-major__images {
      grid-column: 6/span 7;
      grid-row: 1;
      margin-top: -3.75rem
  }
.marquee-major__ending {
      grid-column: 6/span 7;
      grid-row: 2;
      padding: 29rem 0 3rem
  }
.marquee-major__tagline {
      top: 1rem
  }
.marquee-major__tagline.active {
        top: -1rem
    }
.marquee-super {
    padding: 8rem 0 0
}
.marquee-super .heading {
      margin-bottom: -2.25rem
  }
.marquee-super .richtext {
      font-size: 1.15rem;
      grid-column: 6/span 5;
      padding-left: 1.25rem
  }
.marquee-super .video {
      border: 15px solid #FFFFFF;
      grid-column: 6/span 7
  }
.marquee-super::after {
      height: 10rem;
      margin-top: -10rem;
      content: ''
  }
.marquee-two-column {
    padding: 10rem 0 3rem
}
.marquee-two-column__layout {
      grid-template-rows: 100px 1fr
  }
.marquee-two-column__content {
      grid-column: 1/span 4;
      padding-bottom: 3rem
  }
.marquee-two-column__form {
      grid-column: 6/span 7;
      grid-row: 1/span 2;
      margin-bottom: -8rem;
      padding: 3rem

  }
.marquee {
    padding: 10rem 0 3rem
}
.marquee__layout {
      grid-gap: 3rem 1.5rem
  }
.marquee__content {
      grid-column: 3/span 7
  }
.modal--signin .modal__dialog {
        height: fit-content;
        left: auto;
        min-height: 31rem;
        position: absolute;
        right: -.5rem;
        width: 22.25rem
    }
.modal--alternate .modal__dialog {
        padding: 3rem 4rem;
        top: 3rem
    }
.navigation {
    padding: 10rem 0 10rem
}
.navigation__layout {
      grid-gap: 0;
      grid-template-columns: repeat(12, 1fr)
  }
.navigation__nav {
      grid-column: 1/span 5
  }
.navigation__contact {
      grid-column: 7/span 5
  }
.next-article__layout {
      align-items: stretch;
      display: grid;
      grid-template-columns: repeat(12, 1fr)
  }
.next-article__image {
      grid-column: 1/span 6
  }
.next-article__content {
      grid-column: 7/span 6;
      padding: 1.5rem
  }
.panel-content__heading {
      font-size: 2.75rem !important;
      grid-column: 3/span 8
  }
.panel-content__content {
      grid-column: 3/span 8
  }
.parallax-graphic__img .image {
        bottom: 0;
        right: 8%
    }
.picture-inline .figure {
      grid-column: 3/span 8
  }
.picture-inline .picture + .picture {
        margin-top: 0
    }
.picture-inline--fullbleed .figure {
        grid-column: 1/span 12
    }
.picture-inline--narrow .figure {
        grid-column: 4/span 6
    }
.picture-inline--2col .figure {
        display: flex;
        gap: 1.5rem;
        grid-column: 3/span 8
    }
.richtext--fullwidth ul, .richtext--fullwidth ol {
        margin: .25rem 0 0 0
    }
.richtext__layout > * {
        grid-column: 3/span 8
    }
.richtext--full .richtext__layout > * {
          grid-column: 2/span 10
      }
.richtext--narrow .richtext__layout > * {
          grid-column: 4/span 6
      }
.richtext--skinny .richtext__layout > * {
          grid-column: 5/span 3
      }
.richtext--desc .richtext__layout > * {
          grid-column: 5/span 7
      }
.richtext--fullwidth .richtext__layout > * {
          padding-left: 0
      }
.select-clients__title {
      margin: 3rem 0
  }
.select-clients__figure {
      grid-column: 2/span 11;
      margin: 3rem 0
  }
.select-clients__quote {
      padding: 2.5rem 3rem 2.5rem 0
  }
.select-clients__cite .image {
        margin-right: 1.875rem;
        max-width: 5rem
    }
.split-media__content {
      font-size: 1.3125rem;
      grid-column: 7/span 6
  }
.split-media__image {
      grid-column: 1/span 5
  }
.split-media--reversed .split-media__content {
        grid-column: 1/span 6;
        grid-row: 1
    }
.split-media--reversed .split-media__image {
        grid-column: 8/span 5
    }
.text-with-cta__primary {
      grid-column: 1/span 7
  }
.text-with-cta__secondary {
      grid-column: 2/span 7;
      padding-right: 2rem
  }
.text-with-cta__cta {
      grid-column: 9/span 3;
      text-align: right
  }
.three-col-deck {
    padding: 7rem 0
}
.three-col-deck__layout {
      grid-gap: 1.125rem 3rem;
      grid-template-columns: 1fr 1fr 1fr
  }
.three-col-deck__card {
      font-size: 1.125rem
  }
.three-col-deck .richtext {
      margin: 2rem 0
  }
.umbraco-form .turnstyle-grid--1up, .umbraco-form .turnstyle-grid--2up, .umbraco-form .turnstyle-grid--3up, .umbraco-form {
      grid-gap: 1.5rem 3rem
  }
.umbraco-form .turnstyle-grid--2up {
      grid-template-columns: 1fr 1fr
  }
.umbraco-form .turnstyle-grid--3up {
      grid-template-columns: 1fr 1fr 1fr
  }
.grid--2col {
      grid-template-columns: 1fr 1fr
  }
.grid--3col {
      grid-template-columns: 1fr 1fr 1fr
  }
.grid--4col {
      grid-template-columns: 1fr 1fr 1fr 1fr
  }
.grid--major-minor {
      grid-template-columns: 1fr 250px
  }
.pageshell__main {
      min-height: 100vh;
      z-index: 2
  }
.sg-definition {
    padding: 3rem 0
}
.sg-definition--body--grid > * {
          margin-top: 0
      }
.sg-definition--heading {
      grid-template-columns: 1.5fr 6fr 2fr
  }
  }
@media only screen and (min-width: 64rem) {
.social-share .link + .link {
      margin-left: 2ch
  }
.accordion__group--numeric .accordion__head {
      padding-right: 4rem
  }
.blog__card-content {
      grid-template-columns: 2fr 1fr;
      grid-column: 5/span 8
  }
.blog__card-date {
      margin: 1.5rem 0 1rem
  }
.blog__card-link .heading--h2 {
        font-size: 2.5rem
    }
.blog__card-summary {
      padding: 1.5rem 0 0 3rem;
      grid-row: 1/span 2
  }
.blog__card--major .blog__card-content {
        padding: 0 3rem
    }
.blog__card--major .blog__card-link .heading--h2 {
          font-size: 3.25rem;
          line-height: 3.875rem
      }
.carousel__count {
      left: 100px
  }
.carousel__title {
      left: 100px;
      top: 9.8rem
  }
.carousel__controls {
      left: 210px;
      top: 14.875rem
  }
.carousel--basic .carousel__cell {
        padding: 0
    }
.cta-contact__content {
      grid-column: 3/span 4
  }
.cta-contact__sent-content {
      grid-column: 3/span 8
  }
.cta-contact__legend {
      grid-column: 3/span 11
  }
.cta-contact__text-inputs {
      grid-column: 3/span 4
  }
.cta-contact__textarea {
      grid-column: 7/span 4
  }
.icon-grid__intro {
      grid-column: 5/span 6
  }
.latest-work__heading {
      font-size: 1.625rem
  }
.latest-work--major .latest-work__heading, .latest-work--super .latest-work__heading {
        font-size: 2.125rem;
        line-height: 40.8px
    }
.latest-work__card {
    margin-bottom: 0;
    padding-bottom: 5.5rem
}
.marquee-article__image {
      padding-right: 1.5rem
  }
.marquee-article__date {
      grid-column: 4/span 3
  }
.marquee-major__heading .richtext {
        padding-left: 20%
    }
.marquee-major__intro {
      grid-column: 3/span 3
  }
.marquee-major__images {
      grid-column: 6/span 5
  }
.marquee-super .heading {
      margin-bottom: -3.5rem
  }
.marquee-super .richtext {
      grid-column: 6/span 4;
      font-size: 1.0625rem
  }
.marquee-super .video {
      border: 20px solid #FFFFFF
  }
.marquee-super::after {
      height: 20rem;
      margin-top: -15rem;
      content: ''
  }
.marquee-two-column__heading {
      font-size: 4.375rem !important
  }
.marquee-two-column__content {
      grid-column: 3/span 3
  }
.marquee-two-column__form {
      grid-column: 7/span 6;
      grid-row: 1/span 2;
      margin-bottom: -10rem;
      padding: 6.3125rem 6.5rem 5rem

  }
.navigation__contact {
      grid-column: 9/span 4
  }
.next-article__image {
      grid-column: 2/span 5
  }
.next-article__content {
      grid-column: 7/span 5;
      padding: calc(3rem + 1.25rem) 3rem 3rem 3rem
  }
.panel-content__heading {
      font-size: 3.75rem !important;
      grid-column: 3/span 7;
      line-height: 70px
  }
.panel-content__content {
      grid-column: 3/span 7
  }
.parallax-graphic {
    padding: 11rem 0 0
}
.parallax-graphic__layout {
      background-image: url('../images/parallax-bg.jpg');
      background-repeat: no-repeat;
      background-size: contain;
      height: 0;
      padding-bottom: 48%;
      position: relative
  }
.parallax-graphic__mobile-img {
      display: none
  }
.parallax-graphic__img {
      display: block;
      position: relative;
      z-index: 100
  }
.parallax-graphic__img .image {
        position: absolute;
        right: 16%;
        top: -190px;
        z-index: 10
    }
.select-clients__layout {
      grid-template-rows: 1fr 3fr
  }
.select-clients__client {
      padding-right: 8.5rem
  }
.select-clients__figure {
      grid-column: 2/span 7;
      margin: -3rem 0 0 0
  }
.select-clients__img {
      display: block;
      grid-column: 7/span 6;
      grid-row: 1/span 2;
      position: relative;
      right: 0;
      z-index: 0
  }
.select-clients__quote {
      padding: 2.5rem 2.75rem 2.5rem 0
  }
.select-clients__cite {
      padding-right: 8.5rem
  }
.three-col-deck__layout {
      grid-gap: 1.125rem 4.5rem
  }
.three-col-deck__card {
      font-size: 1.3125rem
  }
    }
@media only screen and (min-width: 72rem) {
.marquee-major__heading .heading {
        font-size: 3.75rem;
        margin-bottom: 2.125rem
    }
.next-article__date {
      margin-top: 0
  }
.select-clients__figure {
      grid-column: 2/span 6
  }
      }
@media screen and (min-width: 1280px) {
	.heading--h1, .heading--h1-major, .richtext h1 {
		font-size: 3.25rem;
	}
	.heading--h1-major {
		font-size: 8.625rem;
	}
	.heading--h2, .richtext h2 {
		font-size: 2.5rem;
	}
	.heading--h3, .richtext h3 {
		font-size: 2.125rem;
	}
	.heading--h4, .richtext h4 {
		font-size: 1.75rem;
	}
	.heading--h5, .richtext h5 {
		font-size: 1.3125rem;
	}
	.heading--h6, .richtext h6 {
		font-size: 1.125rem;
	}
	.callout .heading {
		font-size: 2.125rem;
	}
	.cta-contact__legend {
		font-size: 2.125rem;
	}
	.cta-contact p {
		font-size: 2.125rem;
	}
	.icon-grid__intro p {
		font-size: 1.3125rem;
	}
	.marquee-major__tagline {
		font-size: 2.5rem;
	}
	.marquee-two-column__content {
		font-size: 1.3125rem;
	}
	.marquee__heading {
		font-size: 4.375rem;
	}
	.marquee__content {
		font-size: 1.625rem;
	}
	.navigation__nav-item {
		font-size: 3.125rem;
	}
	.navigation__contact {
		font-size: 1.375rem;
	}
	.navigation__contact .heading {
		font-size: 1.375rem;
	}
	.navigation__copy {
		font-size: 1rem;
	}
	.next-article__content i {
		font-size: 1rem;
	}
	.richtext--prominent {
		font-size: 1.75rem;
	}
	.richtext--large {
		font-size: 1.3125rem;
	}
	.select-clients__quote {
		font-size: 1.35rem;
	}
	.text-with-cta {
		font-size: 1.375rem;
	}
}
@media only screen and (min-width: 90rem) {
.wrapper {
    padding: 0 100px
}
.carousel--basic .carousel__title {
        left: 100px;
        top: 7rem
    }
.carousel--basic .carousel__controls {
        left: 100px;
        top: 10rem
    }
.carousel--basic .flickity-viewport {
      width: calc(100% + 100px)
  }
.marquee-major__heading .heading {
        font-size: 4.375rem;
        margin-bottom: 2.125rem
    }
.marquee-major__heading .richtext {
        font-size: 1.25rem;
        padding-left: 40%;
        padding-right: 2.5rem
    }
.select-clients__layout {
      max-width: 1440px;
      padding: 0 50px
  }
.select-clients__img {
      grid-column: 6/span 7;
      right: -6rem
  }
  }
@media only screen and (max-width: 48rem) {
    .sg-definition > * + * {
      margin-top: 1.5rem;
    }
  }
@media screen and (max-width: 420px) {
	.heading--h1, .heading--h1-major, .richtext h1 {
		font-size: 2rem;
	}
	.heading--h1-major {
		font-size: 3.125rem;
	}
	.heading--h2, .richtext h2 {
		font-size: 2rem;
	}
	.heading--h3, .richtext h3 {
		font-size: 1.75rem;
	}
	.heading--h4, .richtext h4 {
		font-size: 1.5rem;
	}
	.heading--h5, .richtext h5 {
		font-size: 1.25rem;
	}
	.heading--h6, .richtext h6 {
		font-size: 1rem;
	}
	.callout .heading {
		font-size: 1.5rem;
	}
	.cta-contact__legend {
		font-size: 1.5rem;
	}
	.cta-contact p {
		font-size: 1.5rem;
	}
	.icon-grid__intro p {
		font-size: 1rem;
	}
	.marquee-major__tagline {
		font-size: 1.75rem;
	}
	.marquee-two-column__content {
		font-size: 1.125rem;
	}
	.marquee__heading {
		font-size: 2rem;
	}
	.marquee__content {
		font-size: 1.125rem;
	}
	.navigation__nav-item {
		font-size: 2.5rem;
	}
	.navigation__contact {
		font-size: 1rem;
	}
	.navigation__contact .heading {
		font-size: .875rem;
	}
	.navigation__copy {
		font-size: .875rem;
	}
	.next-article__content i {
		font-size: .75rem;
	}
	.richtext--prominent {
		font-size: 1.5rem;
	}
	.richtext--large {
		font-size: 1.25rem;
	}
	.select-clients__quote {
		font-size: 1.125rem;
	}
	.text-with-cta {
		font-size: 1.25rem;
	}
}
@media (--smallee) {
.marquee-major__heading .richtext {
        margin: 3rem 0
    }
      }
@media (prefers-reduced-motion: reduce) {
  * {
      animation: none !important;
      transition-duration: 0s !important;
      transition-delay: 0s !important;
  }
}
